.wrapper {
  display: grid;
  grid-template-columns: auto 100px;
  height: 60px;
  border-radius: 0px;
  background-color: #323236;
}

.text {
  border: none;
  outline: none;
  background: none;
  padding: 20px;
  color: white;
  font-size: 16px;
}

.btn {
  border: none;
  border-radius: 0 0px 0px 0;
  font-size: 16px;
  font-weight: bold;
  background-color: rgb(122, 255, 231);
}

.btn:hover {
  cursor: pointer;
  background-color: rgb(0, 204, 167);
}
