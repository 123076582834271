.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  margin: 20px;
  filter: invert(100%);
}