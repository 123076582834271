.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 102;
  backdrop-filter: blur(10px); /* Add blur effect */
}

.modal {
  background-color: #2f2f2f;
  padding: 20px;
  border: 1px solid #000000;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 103; /* Ensure modal is above the overlay */
  pointer-events: auto; /* Ensure modal is interactive */
}

.textarea {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 10px;
  background: #2f2f2f;
}

.button {
  background-color: #4CAF50;
  border: none;
  color: #1026d1;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  /*margin: 4px 2px;*/
  cursor: pointer;
  border-radius: 5px;
}
