.wrapper {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 0px;
  background-color: #1b1b1d;
}

.wrapper:hover {
  cursor: pointer;
  background-color: #323236;
}

.wrapper {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 0px;
  background-color: #1b1b1d;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper:hover {
  cursor: pointer;
  background-color: #323236;
}

.deleteButton {
  background: none;
  border: none;
  color: #ff5c5c;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 10px;
}

.deleteButton:hover {
  color: #ff1c1c;
}