.wrapper {
  display: flex;
  /*align-items: center;*/
  margin-bottom: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  margin: 20px;
  filter: invert(100%);
}

button {
  background-color: #4CAF50;
  border: none;
  color: #ffffff;
  padding: 1px 2px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  border-radius: 1px;
}

button:hover {
  background-color: #d20c0c;
}


.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

a {
  color: cyan;
}

a:visited {
  color: yellow;
}