/*Exit.module.css*/

.wrapper {
  width: 100%;
  height: 60px;
  background-color: #FF7A98;
  border: none;
  border-radius: 0px;
  font-size: 16px;
  font-weight: bold;
}

.wrapper:hover {
  cursor: pointer;
  background-color: #FD2C5B;
}
