/*App.css*/

.App {
  display: grid;
  grid-template-columns: auto 200px;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
  min-height: 100vh;
  padding: 20px;
}

.Column {
  color: white;
}

.Title {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 0px;
  color: black;
  background-color: rgb(237, 241, 21);
  display: flex;
  justify-content: space-between; /* Space out username and logout button */
  align-items: center;
}

.Content {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  margin-bottom: 20px;
}

::-webkit-scrollbar {
  display: none;
}
