/* Chat.module.css */

.titleWrapper {
  display: grid;
  grid-template-columns: auto 100px; /* Similar layout as Input */
  height: 60px;
  border-radius: 0px;
  align-items: center;
  padding: 0 0 0 20px;
  background-color:rgb(237, 241, 21);
  margin-bottom: 20px;
}

.username {
  color: black;
  font-weight: bold;
  font-size: 16px;
}

.logoutBtn {
  border: none;
  border-radius: 0;
  font-size: 16px;
  font-weight: bold;
  color: black;
  background-color: #FF7A98;
  height: 100%;
}

.logoutBtn:hover {
  cursor: pointer;
  background-color: #FD2C5B;
}
